/** @jsx jsx */
import { Box, Flex, Heading, jsx } from "theme-ui";
import React from "react";
import Image from "../utils/image/image";

import { Link } from "gatsby";
import { ProjectCardProps } from "./project-card";
import MarkDown, { StyledMarkDown } from "../utils/markdown/markdown";

export const ProjectCardExtended: React.FC<ProjectCardProps> = ({
  image,
  slug,
  name,
  content,
  technology,
  cms,
  services,
}) => {
  //const [showDetail, setShowDetail] = useState(false);
  const techAndCms = [...technology, cms];
  return (
    <div
      sx={{
        variant: "cards.primary",
        p: 0,
        position: "relative",
      }}
    >
      <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
        <Heading pb={2} as="h2" sx={{ textAlign: "center" }}>
          {name}
        </Heading>
        <Box sx={{ fontSize: [3], textAlign: "center" }}>
          {services.join(" \u2022 ")}
        </Box>
        <Box sx={{ fontWeight: ["light"], textAlign: "center" }}>
          {techAndCms.join(" \u2022 ")}
        </Box>

        <Image
          sx={{
            my: 3,
            borderRadius: "4px",
            width: ["300px", "400px"],
          }}
          {...image}
          alt={name}
          loading="lazy"
        />

        <StyledMarkDown
          sx={{ textAlign: "center", color: "text", fontWeight: "light" }}
          data={content.childMarkdownRemark.excerpt}
        />
      </Flex>
      <Link sx={{ color: "primary", textAlign: "center" }} to={`${slug}`}>
        Read more about how we helped {name}
      </Link>
    </div>
  );
};

export default ProjectCardExtended;
