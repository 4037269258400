/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { graphql } from "gatsby";
import WidgetSelector from "../components/utils/widget/widget-selector";
import Layout from "../components/layout/layout.component";
import { StyledMarkDown } from "../components/utils/markdown/markdown";
import SEO from "../components/utils/seo/seo";
import { getSeoMeta } from "../components/utils/seo/helper-functions";

export type PageTemplateProps = { data: any };
export const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  //console.log(data.contentfulPage.pageSections);
  //console.log(data.contentfulPage.pageSections[0].components[0].__typename);

  const sections: any[] = data.contentfulPage.pageSections;
  const content = data.contentfulPage.content?.childMarkdownRemark?.html;

  const SectionElements = sections.map((section) => {
    const components: any[] = section.components;
    return components.map((component) => {
      const ComponentElement = WidgetSelector[component.type];
      return (
        <div key={component.id}>
          <ComponentElement {...component}></ComponentElement>
        </div>
      );
    });
  });
  return (
    <Layout>
      <SEO {...getSeoMeta(data.contentfulPage)}></SEO>
      {SectionElements}
      <div sx={{ variant: "layout.narrow" }}>
        <StyledMarkDown data={content}></StyledMarkDown>
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      description
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      seoMetadata {
        title
        robotsNoIndex
        robotsNoFollow
        description
        facebookSeo {
          name
          type
          image {
            localFile {
              publicURL
            }
          }
          imageAlt
        }
        twitterSeo {
          title
          card
        }
      }
      pageSections {
        title
        components {
          ...Hero
          ...TextArea
          ...CTA
          ...CardCollection
          ...Testimonials
          ...InfoBar
          ...ContentTwoColumn
          ...Service
          ...ProjectList
        }
      }
    }
  }
`;
