/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Markdown } from "../components/utils/markdown/markdown.interface";
import MarkDown from "../components/utils/markdown/markdown";
import Quote from "../components/design-elements/quote";

export type TestimonialProps = {
  person: string;
  jobTitle: string;
  company: string;
  testimonial: Markdown;
};
export const Testimonial: React.FC<TestimonialProps> = ({
  person,
  jobTitle,
  company,
  testimonial,
}) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: 4,
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div sx={{ color: "primary", width: "60px" }}>
        <Quote></Quote>
      </div>
      <div>
        <svg
          width="540"
          height="1"
          viewBox="0 0 540 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="0.5" x2="540" y2="0.5" stroke="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="0"
              y1="1.5"
              x2="540"
              y2="1.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F6D365" />
              <stop offset="1" stopColor="#FDA085" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div sx={{ pt: 4, color: "white", fontSize: 5 }}>
        <MarkDown data={testimonial}></MarkDown>
      </div>
      <div sx={{ pt: 5, color: "white", fontWeight: "bold", fontSize: 4 }}>
        {person}
      </div>
      <div
        sx={{ color: "textMuted", fontWeight: "light", fontSize: 3 }}
      >{`${jobTitle}, ${company}`}</div>
    </div>
  );
};

export default Testimonial;
