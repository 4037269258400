/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Markdown } from "../utils/markdown/markdown.interface";
import MarkDown, { StyledMarkDown } from "../utils/markdown/markdown";

export type ContentTwoColumnTextProps = {
  contentHeading: string;
  message: Markdown;
  content: Markdown;
};
export const ContentTwoColumnText: React.FC<ContentTwoColumnTextProps> = ({
  contentHeading,
  message,
  content,
}) => {
  return (
    <div sx={{}}>
      <div
        sx={{
          variant: "layout.box",
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: "space-between",
          height: "100%",
          textAlign: ["center", "left"],
          pb: 0,
        }}
      >
        {/** Col 1 */}
        <div
          sx={{
            width: ["100%", "40%", "40%"],
            display: "flex",
            flexDirection: "column",
            //justifyContent: "space-around",
          }}
        >
          <div>
            <h2
              sx={{
                fontSize: [4, 4, 5],
                fontWeight: "body",
                lineHeight: 1,
                color: "primary",
                textTransform: "uppercase",
              }}
            >
              {contentHeading}
            </h2>
            <div
              sx={{
                color: ["textMuted", "text"],
                fontWeight: "light",
                fontSize: [3, 3, 3],
                pt: 2,
              }}
            >
              <MarkDown data={message} />
            </div>
          </div>
        </div>
        {/** Col 2 */}
        <div
          sx={{
            width: ["100%", "50%", "50%"],
            display: "flex",
            justifyContent: "flex-end",
            //alignItems: "center",
            fontSize: 3,
            fontWeight: "light",
            pt: [3, 0, 0],
          }}
        >
          {content && <StyledMarkDown data={content} />}
        </div>
      </div>
    </div>
  );
};

export default ContentTwoColumnText;
