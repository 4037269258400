/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const ECommerce: React.FC = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 68 60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M61.8891 35.3108L67.4289 10.9358C67.8288 9.1759 66.4911 7.5 64.6863 7.5H18.6572L17.583 2.24883C17.3154 0.939961 16.1637 0 14.8276 0H2.8125C1.25918 0 0 1.25918 0 2.8125V4.6875C0 6.24082 1.25918 7.5 2.8125 7.5H11.0019L19.2341 47.7463C17.2646 48.8789 15.9375 51.0026 15.9375 53.4375C15.9375 57.0619 18.8756 60 22.5 60C26.1244 60 29.0625 57.0619 29.0625 53.4375C29.0625 51.6007 28.307 49.9412 27.0909 48.75H51.6589C50.443 49.9412 49.6875 51.6007 49.6875 53.4375C49.6875 57.0619 52.6256 60 56.25 60C59.8744 60 62.8125 57.0619 62.8125 53.4375C62.8125 50.8392 61.3022 48.5939 59.1118 47.5307L59.7584 44.6858C60.1583 42.9259 58.8206 41.25 57.0158 41.25H25.5606L24.7936 37.5H59.1465C60.4597 37.5 61.5981 36.5913 61.8891 35.3108Z" />
    </svg>
  );
};

export default ECommerce;
