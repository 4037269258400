/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export type LeftAngleProps = {
  sx?: string;
  className?: string;
  height?: string;
  width?: string;
};
export const LeftAngle: React.FC<LeftAngleProps> = ({
  className,
  width,
  height,
}) => {
  width = width ? width : "100%";
  height = height ? height : "100%";
  return (
    <svg
      className={className}
      fill="currentColor"
      width={width}
      height={height}
      viewBox="0 0 16 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points="13,2 2,25 13,48"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
};

export default LeftAngle;
