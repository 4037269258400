/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Button, { ButtonProps } from "../buttons/linkButtons/Button";

export type CTAProps = { button: ButtonProps };
export const CTA: React.FC<CTAProps> = ({ button }) => {
  return (
    <div sx={{ variant: "layout.box", textAlign: "center" }}>
      <Button variant="primary" {...button} />
    </div>
  );
};

export default CTA;
