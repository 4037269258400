/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const Seo: React.FC = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 60 60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41.5385 25.3846C41.5385 20.9375 39.9579 17.1334 36.7969 13.9724C33.6358 10.8113 29.8317 9.23077 25.3846 9.23077C20.9375 9.23077 17.1334 10.8113 13.9724 13.9724C10.8113 17.1334 9.23077 20.9375 9.23077 25.3846C9.23077 29.8317 10.8113 33.6358 13.9724 36.7969C17.1334 39.9579 20.9375 41.5385 25.3846 41.5385C29.8317 41.5385 33.6358 39.9579 36.7969 36.7969C39.9579 33.6358 41.5385 29.8317 41.5385 25.3846ZM60 55.3846C60 56.6346 59.5433 57.7163 58.6298 58.6298C57.7163 59.5433 56.6346 60 55.3846 60C54.0865 60 53.0048 59.5433 52.1394 58.6298L39.7716 46.2981C35.4688 49.2788 30.6731 50.7692 25.3846 50.7692C21.9471 50.7692 18.6599 50.1022 15.5228 48.768C12.3858 47.4339 9.68149 45.631 7.40986 43.3594C5.13822 41.0877 3.33534 38.3834 2.0012 35.2464C0.667067 32.1094 0 28.8221 0 25.3846C0 21.9471 0.667067 18.6599 2.0012 15.5228C3.33534 12.3858 5.13822 9.68149 7.40986 7.40986C9.68149 5.13822 12.3858 3.33534 15.5228 2.0012C18.6599 0.667067 21.9471 0 25.3846 0C28.8221 0 32.1094 0.667067 35.2464 2.0012C38.3834 3.33534 41.0877 5.13822 43.3594 7.40986C45.631 9.68149 47.4339 12.3858 48.768 15.5228C50.1022 18.6599 50.7692 21.9471 50.7692 25.3846C50.7692 30.6731 49.2788 35.4688 46.2981 39.7716L58.6659 52.1394C59.5553 53.0288 60 54.1106 60 55.3846Z" />
    </svg>
  );
};

export default Seo;
