/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export type RightAngleProps = {
  sx?: string;
  className?: string;
  height?: string;
  width?: string;
};
export const RightAngle: React.FC<RightAngleProps> = ({
  className,
  height,
  width,
}) => {
  width = width ? width : "100%";
  height = height ? height : "100%";
  return (
    <svg
      className={className}
      fill="currentColor"
      width={width}
      height={height}
      viewBox="0 0 16 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points="2,2 13,25 2,48"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
};

export default RightAngle;
