/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export type SectionPointProps = { sx?: string; className?: string };
export const SectionPoint: React.FC<SectionPointProps> = ({ children }) => {
  return (
    <div>
      <div sx={{ bg: "supportingALight" }}>{children}</div>
      <div>
        <svg
          sx={{ display: "block" }}
          viewBox="0 0 1000 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0 L 500 45 L1000 0 Z"
            fill="#45494e"
          />
        </svg>
      </div>
    </div>
  );
};

export default SectionPoint;
