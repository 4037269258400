/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const Quote: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 52 38"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.6667 0.666748L11.3333 15.3334H22.3333V37.3334H0.333332V15.3334L7.66667 0.666748H18.6667ZM48 0.666748L40.6667 15.3334H51.6667V37.3334H29.6667V15.3334L37 0.666748H48Z" />
    </svg>
  );
};

export default Quote;
