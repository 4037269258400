/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Testimonial, { TestimonialProps } from "./testimonial.component";
import SectionPoint from "../components/sections/section-point.component";
import RockitSlider from "../components/slider/rw-slider.component";

export type TestimonialsProps = { testimonials: TestimonialProps[] };
export const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  //const firstTestimonial = testimonials[0]

  const testimonialSlides = testimonials.map((t) => (
    <Testimonial key={t.person} {...t} />
  ));

  return (
    <div sx={{ py: 4 }}>
      <SectionPoint>
        <div sx={{ variant: "layout.box" }}>
          <RockitSlider>{testimonialSlides}</RockitSlider>
        </div>
      </SectionPoint>
    </div>
  );
};

export default Testimonials;
