import Testimonials from "../../../testimonials/testimonials.component";
import ContentTwoColumn from "../../content/content-two-column";
import ContentTwoColumnText from "../../content/content-two-column-text";
import CTA from "../../cta/cta.component";
import Hero from "../../hero/hero.component";
import InfoBarSingleLine from "../../info-bars/info-bar-single-line";
import InfoBarTwoLine from "../../info-bars/info-bar-two-line";
import ProjectList from "../../projects/project-list";
import Service from "../../service/service";
import Services from "../../services/services";
import TextArea from "../../text-area/text-area.component";

const WidgetSelector = {
  Hero: Hero,
  TextArea: TextArea,
  CTA: CTA,
  Services: Services,
  Testimonials: Testimonials,
  InfoBar: InfoBarSingleLine,
  InfoBar2Line: InfoBarTwoLine,
  PageContentLeft: ContentTwoColumn,
  PageContentRight: ContentTwoColumn,
  PageContentText: ContentTwoColumnText,
  Service: Service,
  ProjectList: ProjectList,
};

export default WidgetSelector;
