/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { IconCard } from "./icon-card.interface";
import MarkDown from "../utils/markdown/markdown";
import IconSelector from "../design-elements/icons/icon-selector";
import LeftAngle from "../design-elements/left-angle";
import RightAngle from "../design-elements/right-angle";

export type IconCardProps = {
  sx?: string;
} & IconCard;
export const IconCardSimple: React.FC<IconCardProps> = ({
  heading,
  content,
  icon,
}) => {
  const IconElement = IconSelector[icon];

  return (
    <div
      data-sal="zoom-in"
      data-sal-delay="200"
      data-sal-duration="1000"
      data-sal-easing="ease-in-out-bounce"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div sx={{ display: "flex" }}>
        <div sx={{ height: "60px" }}>
          <LeftAngle sx={{ color: "primaryTransparent" }} />
        </div>
        <div sx={{ color: "primary", height: "60px" }}>
          <IconElement />
        </div>
        <div sx={{ height: "60px" }}>
          <RightAngle sx={{ color: "primaryTransparent" }} />
        </div>
      </div>

      <h2 sx={{ my: 2 }}>{heading}</h2>
      <div sx={{ fontWeight: "light", fontSize: 3, textAlign: "center" }}>
        <MarkDown data={content}></MarkDown>
      </div>
    </div>
  );
};

export default IconCard;
