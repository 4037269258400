/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import { Icon } from "@iconify/react";
import chevronLeft from "@iconify/icons-codicon/chevron-left";
import debugPause from "@iconify/icons-codicon/debug-pause";
import playIcon from "@iconify/icons-codicon/play";
import chevronRight from "@iconify/icons-codicon/chevron-right";

export type SliderControlsProps = {
  paused: boolean;
  onPause: (value: boolean) => void;
  onPrevious: () => void;
  onNext: () => void;
};
export const SliderControls: React.FC<SliderControlsProps> = ({
  paused,
  onPause,
  onPrevious,
  onNext,
}) => {
  return (
    <div
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "primary",
      }}
    >
      <div onClick={onPrevious}>
        <Icon icon={chevronLeft} style={{ fontSize: "60px" }} />
      </div>
      <div
        sx={{ display: paused ? "block" : "none" }}
        onClick={() => onPause(true)}
      >
        <Icon icon={debugPause} style={{ fontSize: "60px" }} />
      </div>
      <div
        sx={{ display: !paused ? "block" : "none" }}
        onClick={() => onPause(false)}
      >
        <Icon icon={playIcon} style={{ fontSize: "60px" }} />
      </div>
      <div onClick={onNext}>
        <Icon icon={chevronRight} style={{ fontSize: "60px" }} />
      </div>
    </div>
  );
};

export default SliderControls;
