/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ProjectCardExtended from "../cards/project-card-extended";

export type ProjectListProps = null;
export const ProjectList: React.FC<ProjectListProps> = ({}) => {
  const projects = useStaticQuery(query).allContentfulProject.edges.map(
    (n) => n.node
  );

  const projectElements = projects.map((project) => {
    return <ProjectCardExtended key={project.name} {...project} />;
  });

  return (
    <Box variant="layout.box">
      <Grid gap={["50px", "50px"]} columns={[1, 2]}>
        {projectElements}
      </Grid>
    </Box>
  );
};

export default ProjectList;

const query = graphql`
  {
    allContentfulProject(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          name
          technology
          url
          services
          slug
          performance
          accessibility
          bestPractices
          seo
          seoMetadata {
            title
            robotsNoIndex
            robotsNoFollow
            description
            facebookSeo {
              name
              type
              image {
                localFile {
                  publicURL
                }
              }
              imageAlt
            }
            twitterSeo {
              title
              card
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
              excerpt(format: HTML)
            }
          }
          design
          cms
          client
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 400)
            file {
              contentType
              url
            }
          }
        }
      }
    }
  }
`;
