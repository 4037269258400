/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { Children, useEffect, useState } from "react";
import SliderControls from "./slider-controls";
export const RockitSlider: React.FC = ({ children }) => {
  //get an array of our children
  const childSlides = Children.toArray(children);

  const [autoPlay, setAutoPlay] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slide, setSlide] = useState(childSlides[0]);
  useEffect(() => {
    //console.log(activeTestimonial, testimonials.length);
    const interval = setInterval(() => {
      const index = activeIndex < childSlides.length - 1 ? activeIndex + 1 : 0;

      if (autoPlay) {
        setActiveIndex(index);
        setSlide(childSlides[index]);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  });
  const navigatePrevious = () => {
    if (activeIndex === 0) return;
    setActiveIndex(activeIndex - 1);
    setSlide(childSlides[activeIndex]);
  };
  const navigateNext = () => {
    if (activeIndex === childSlides.length - 1) return;
    setActiveIndex(activeIndex + 1);
    setSlide(childSlides[activeIndex]);
  };

  return (
    <div sx={{ cursor: "pointer", position: "relative" }}>
      <div
        sx={{
          height: "100%",
          left: 0,
          top: 0,
          position: "absolute",
          width: "100%",
          zIndex: 2,

          opacity: 0,
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        <SliderControls
          paused={autoPlay}
          onPause={(value) => {
            setAutoPlay(!value);
          }}
          onPrevious={navigatePrevious}
          onNext={navigateNext}
        />
      </div>

      {slide}
    </div>
  );
};

export default RockitSlider;
