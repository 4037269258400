/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const Startup: React.FC = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 42 60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.257 53.2434C11.2582 53.9805 11.4762 54.7024 11.8852 55.3164L13.8879 58.327C14.23 58.8416 14.6941 59.2637 15.2387 59.5557C15.7834 59.8476 16.3918 60.0004 17.0098 60.0004H24.2414C24.8594 60.0004 25.4678 59.8476 26.0125 59.5557C26.5571 59.2637 27.0212 58.8416 27.3633 58.327L29.366 55.3164C29.7748 54.7023 29.9933 53.9812 29.9941 53.2434L29.9988 48.7492H11.2512L11.257 53.2434ZM0 20.6242C0 25.8239 1.92773 30.5676 5.10469 34.1922C7.04063 36.4012 10.0687 41.016 11.223 44.909C11.2277 44.9395 11.2312 44.97 11.2359 45.0004H30.0141C30.0187 44.97 30.0223 44.9407 30.027 44.909C31.1813 41.016 34.2094 36.4012 36.1453 34.1922C39.3223 30.5676 41.25 25.8239 41.25 20.6242C41.25 9.21136 31.9816 -0.0359108 20.5605 -0.000754581C8.60625 0.0355735 0 9.72229 0 20.6242ZM20.625 11.2492C15.4559 11.2492 11.25 15.4551 11.25 20.6242C11.25 21.6602 10.4109 22.4992 9.375 22.4992C8.33906 22.4992 7.5 21.6602 7.5 20.6242C7.5 13.3867 13.3875 7.49925 20.625 7.49925C21.6609 7.49925 22.5 8.33831 22.5 9.37425C22.5 10.4102 21.6609 11.2492 20.625 11.2492Z" />
    </svg>
  );
};

export default Startup;
