/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Markdown } from "../utils/markdown/markdown.interface";
import MarkDown from "../utils/markdown/markdown";
import { Icon } from "@iconify/react";
import chevronCircleRight from "@iconify/icons-fa-solid/chevron-circle-right";
export type ServiceProps = {
  contentHeading: string;
  message: Markdown;
  features: string[];
  //cta: ButtonProps;
  //image: ImageProps;
  //type: "PageContentLeft" | "PageContentRight";
};
export const Service: React.FC<ServiceProps> = ({
  contentHeading,
  message,
  features,
}) => {
  const featuresList = features.map((f, i) => (
    <li
      data-sal="fade"
      data-sal-delay={i * 200}
      data-sal-easing="ease"
      data-sal-duration="1000"
      sx={{
        textTransform: "uppercase",
        color: "text",
        fontWeight: "body",
        mb: 1,
        px: 2,
        pb: 2,
        // pl:3,
        fontSize: [0, 2, 2],
        listStyle: "none",
        verticalAlign: "middle",
        display: "flex",
        //flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-between",
      }}
      key={f}
    >
      <Icon icon={chevronCircleRight} sx={{ fontSize: 1, color: "primary" }} />{" "}
      <div sx={{ pl: 1 }}>{f}</div>
    </li>
  ));

  return (
    <div sx={{ mb: 4 }}>
      <div
        sx={{
          variant: "layout.box",
          display: "flex",
          flexDirection: ["column", "row"],
          height: "100%",
          pb: 0,
        }}
      >
        {/** Col 1 */}
        <div
          sx={{
            width: ["100%", "50%"],
            display: "flex",
            flexDirection: "column",
            //justifyContent: "center",
            textAlign: ["center", "left"],
            pb: [2, 0, 0],
          }}
        >
          <div>
            <h2
              sx={{
                fontSize: [4, 4, 5],
                fontWeight: "body",
                lineHeight: 1,
                color: "primary",
                textTransform: "uppercase",
              }}
            >
              {contentHeading}
            </h2>
            <div sx={{ fontWeight: "light", fontSize: [4, 4, 4], pt: 2 }}>
              <MarkDown data={message} />
            </div>
          </div>
        </div>
        {/** Col 2 */}
        <div
          sx={{
            width: ["100%", "50%"],
            display: "flex",
            justifyContent: ["center", "flex-end"],
            alignItems: "flex-start",
            px: 3,
          }}
        >
          <ul
            sx={{
              width: ["100%"],
              display: "flex",

              flexWrap: "wrap",
              m: 0,
              pl: 0,
              //justifyContent: ["space-around", "space-between"],
            }}
          >
            {featuresList}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Service;
