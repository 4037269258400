/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Markdown } from "../utils/markdown/markdown.interface";
import Button, { ButtonProps } from "../buttons/linkButtons/Button";
import MarkDown from "../utils/markdown/markdown";
import { ImageProps } from "../utils/image/Interfaces";
import Image from "../utils/image/image";

export type ContentTwoColumnProps = {
  contentHeading: string;
  message: Markdown;
  cta: ButtonProps;
  image: ImageProps;
  type: "PageContentLeft" | "PageContentRight";
};
export const ContentTwoColumn: React.FC<ContentTwoColumnProps> = ({
  contentHeading,
  message,
  cta,
  image,
  type,
}) => {
  const direction = type === "PageContentLeft" ? "row" : "row-reverse";

  return (
    <div sx={{ position: "relative", height: ["max-content", "500px"] }}>
      <div
        id={contentHeading}
        key={contentHeading}
        data-sal="fade"
        data-sal-repeat
        data-sal-duration="0"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          bg: "supportingAExtraLight",
          zIndex: -1,
          //borderTop: "#979797 solid 1px",
          //borderBottom: "#979797 solid 1px",

          //opacity: active ? 1 : 0,
        }}
      ></div>
      <div
        sx={{
          variant: "layout.box",
          display: "flex",
          flexDirection: ["column-reverse", direction],
          height: "max-content",
          zIndex: 2,
        }}
      >
        {/** Col 1 */}
        <div
          sx={{
            width: ["100%", "50%"],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div sx={{ textAlign: ["center", "left"] }}>
            <h2
              sx={{
                fontSize: [6, 6, 6],
                fontWeight: "body",
                lineHeight: "60px",
              }}
            >
              {contentHeading}
            </h2>
            <div sx={{ fontWeight: "light", fontSize: [4, 4, 4], pt: 2 }}>
              <MarkDown data={message} />
            </div>
          </div>
          <div sx={{ justifySelf: "flex-end" }}>
            {cta && <Button variant="primary.outline" {...cta}></Button>}
          </div>
        </div>
        {/** Col 2 */}
        <div
          sx={{
            width: ["100%", "50%"],
            display: "flex",
            justifyContent:
              type === "PageContentLeft"
                ? ["center", "flex-end"]
                : ["center", "flex-start"],
            alignItems: "center",
          }}
        >
          {image && (
            <Image
              sx={{ width: ["50%", "400px"], height: ["50%", "400px"] }}
              {...image}
            ></Image>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentTwoColumn;
