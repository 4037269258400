/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import MarkDown from "../utils/markdown/markdown";

export type TextAreaProps = { heading: string; content: string };
export const TextArea: React.FC<TextAreaProps> = ({ heading, content }) => {
  return (
    <div
      sx={{
        variant: "layout.narrow",
        maxWidth: "750px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 3,
      }}
    >
      <div sx={{ fontWeight: "bold", pb: 2, textAlign: "center" }}>
        <MarkDown data={heading}></MarkDown>
      </div>
      <div sx={{ fontWeight: "light", textAlign: "center", pb: 2 }}>
        <MarkDown data={content}></MarkDown>
      </div>
    </div>
  );
};

export default TextArea;
