/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import IconCard, { IconCardSimple } from "../cards/icon-card";

export type ServicesProps = { cards: IconCard[] };
export const Services: React.FC<ServicesProps> = ({ cards }) => {
  const cardElements = cards.map((card, i) => {
    return <IconCardSimple key={i} {...card} />;
  });

  return (
    <div sx={{ variant: "layout.box" }}>
      <div
        sx={{
          display: "grid",
          gridGap: 4,
          gridTemplateColumns: ["3", "1fr 1fr 1fr"],
          gridRowGap: "50px",
        }}
      >
        {cardElements}
      </div>
    </div>
  );
};

export default Services;
