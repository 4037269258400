import Development from "./development";
import ECommerce from "./eCommerce";
import MobileDevelopment from "./mobile-development";
import Seo from "./seo";
import Startup from "./startup";
import WebDesign from "./web-design";

const IconSelector = {
  Development: Development,
  MobileDevelopment: MobileDevelopment,
  ECommerce: ECommerce,
  SEOOptimisation: Seo,
  WebDesign: WebDesign,
  Startup: Startup,
};

export default IconSelector;
