/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Button, { ButtonProps } from "../buttons/linkButtons/Button";

export type InfoBarTwoLineProps = {
  line1: string;
  line2: string;
  cta: ButtonProps;
  sx?: any;
  className?: string;
};
export const InfoBarTwoLine: React.FC<InfoBarTwoLineProps> = ({
  line1,
  line2,
  cta,
  className,
}) => {
  return (
    <div
      className={className}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "150px",
        bg: "supportingAExtraLight",
        textAlign: "center",
      }}
    >
      <div
        sx={{
          variant: "layout.box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div sx={{ color: "text", fontSize: [4, 6, 6] }}>{line1}</div>
        <div sx={{ color: "text", fontWeight: "thin", fontSize: [4, 6, 6] }}>
          {line2}
        </div>
        {cta && <Button variant="primary" {...cta}></Button>}
      </div>
    </div>
  );
};

export default InfoBarTwoLine;
