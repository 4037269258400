/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const MobileDevelopment: React.FC = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 36 60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.75 54C21.75 52.9688 21.3828 52.0859 20.6484 51.3516C19.9141 50.6172 19.0312 50.25 18 50.25C16.9688 50.25 16.0859 50.6172 15.3516 51.3516C14.6172 52.0859 14.25 52.9688 14.25 54C14.25 55.0312 14.6172 55.9141 15.3516 56.6484C16.0859 57.3828 16.9688 57.75 18 57.75C19.0312 57.75 19.9141 57.3828 20.6484 56.6484C21.3828 55.9141 21.75 55.0312 21.75 54ZM31.5 46.5V13.5C31.5 13.0938 31.3516 12.7422 31.0547 12.4453C30.7578 12.1484 30.4062 12 30 12H6C5.59375 12 5.24219 12.1484 4.94531 12.4453C4.64844 12.7422 4.5 13.0938 4.5 13.5V46.5C4.5 46.9062 4.64844 47.2578 4.94531 47.5547C5.24219 47.8516 5.59375 48 6 48H30C30.4062 48 30.7578 47.8516 31.0547 47.5547C31.3516 47.2578 31.5 46.9062 31.5 46.5ZM22.5 6.75C22.5 6.25 22.25 6 21.75 6H14.25C13.75 6 13.5 6.25 13.5 6.75C13.5 7.25 13.75 7.5 14.25 7.5H21.75C22.25 7.5 22.5 7.25 22.5 6.75ZM36 6V54C36 55.625 35.4062 57.0312 34.2188 58.2188C33.0312 59.4062 31.625 60 30 60H6C4.375 60 2.96875 59.4062 1.78125 58.2188C0.59375 57.0312 0 55.625 0 54V6C0 4.375 0.59375 2.96875 1.78125 1.78125C2.96875 0.59375 4.375 0 6 0H30C31.625 0 33.0312 0.59375 34.2188 1.78125C35.4062 2.96875 36 4.375 36 6Z" />
    </svg>
  );
};

export default MobileDevelopment;
