/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Button, { ButtonProps } from "../buttons/linkButtons/Button";

export type InfoBarSingleLineProps = {
  line1: string;
  cta: ButtonProps;
  sx?: any;
  className?: string;
};
export const InfoBarSingleLine: React.FC<InfoBarSingleLineProps> = ({
  line1,
  cta,
  className,
}) => {
  return (
    <div
      className={className}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "150px",
        bg: "supportingALight",
      }}
    >
      <div
        sx={{
          variant: "layout.box",
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: ["space-around"],
          alignItems: ["center"],
          textAlign: "center",
        }}
      >
        <div sx={{ color: "white", pb: [3, 0, 0], fontSize: [4, 5, 5] }}>
          {line1}
        </div>

        <Button
          sx={{ width: "max-content" }}
          variant="primary"
          {...cta}
        ></Button>
      </div>
    </div>
  );
};

export default InfoBarSingleLine;
